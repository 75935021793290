<template>
  <div :class="[{ background: MltID == null }]">
    <v-row justify="center">
      <v-col v-for="meal in meals" v-if="!horizontal">
        <v-card elevation="2" rounded="" color="grey lighten-4" dense v-for="m in meal.menus" style="margin-bottom: 5px">
          <div>
            <s-toolbar
              style="
                font-size: 16px !important;
                font-weight: 400 !important;
              "
              :label="m.MealTypeText + ' ' + m.MenuTypeText"
            >
            </s-toolbar>
            <v-card-text style="background: white;">
              <v-container>
                <v-row>
                  <v-col lg="12">
                    <v-list-item-title style="font-size: 14px; opacity: 0.8"><b> Reservas</b></v-list-item-title></v-col
                  >
                  <v-spacer></v-spacer>
                  <v-col lg="4" style="padding-right: 12px;">
                    <p class="text-center">Reservas</p>
                    <v-spacer></v-spacer>
                    <h1
                      class="text-center"
                      style="
                    font-size: 32px;
                    margin: 0px;
                    padding: 0px;
                    color: var(--v-success-base);
                  "
                    >
                      {{ m.MndReserve }}
                    </h1>
                  </v-col>
                  <v-col lg="4" style="padding-right: 12px;">
                    <p class="text-center">Atendidas</p>
                    <v-spacer></v-spacer>
                    <h1
                      class="text-center"
                      style="
                    font-size: 32px;
                    margin: 0px;
                    padding: 0px;
                    color: var(--v-success-base);
                  "
                    >
                      {{ m.MndReserveConfirmate }}
                    </h1>
                  </v-col>
                  <v-col lg="4" style="padding-right: 12px;">
                    <p class="text-center">Pendiente</p>
                    <v-spacer></v-spacer>
                    <h1
                      class="text-center"
                      style="
                    font-size: 32px;
                    margin: 0px;
                    padding: 0px;
                    color: var(--v-success-base);
                  "
                    >
                      {{ m.MndReserve - m.MndReserveConfirmate }}
                    </h1>
                  </v-col>
                </v-row>
              </v-container>
              <v-progress-linear
                stream
                rounded=""
                :buffer-value="getPorcentaje(m.MndReserve, m.MndReserveConfirmate)"
                :value="getPorcentaje(m.MndReserve, m.MndReserveConfirmate)"
                color="success"
              ></v-progress-linear>

              <v-row>
                <v-col lg="12" style="padding-bottom:0px;">
                  <v-list-item-title style="font-size: 14px; opacity: 0.8"> <b> Extemporaneas</b></v-list-item-title>
                </v-col>
                <v-col lg="4" class="" style="padding-right: 12px;">
                  <p class="text-center">Reservas</p>
                  <v-spacer></v-spacer>
                  <h1
                    class="text-center"
                    style="
                    font-size: 32px;
                    margin: 0px;
                    padding: 0px;
                    color: var(--v-warning-base); 
                  "
                  >
                    {{ m.MndUnplanned }}
                  </h1>
                </v-col>
                <v-col lg="4" class="" style="padding-right: 12px;padding-top: 0px">
                  <p class="text-center">Atendidas</p>
                  <v-spacer></v-spacer>
                  <h1
                    class="text-center"
                    style="
                    font-size: 32px;
                    margin: 0px;
                    padding: 0px;
                    color: var(--v-warning-base); 
                  "
                  >
                    {{ m.MndUnplannedConfirmate }}
                  </h1>
                </v-col>
                <v-col lg="4" style="padding-right: 12px;padding-top: 0px">
                  <p class="text-center">Pendiente</p>
                  <v-spacer></v-spacer>
                  <h1
                    class="text-center"
                    style=" 
                    font-size: 32px;
                    margin: 0px;
                    padding: 0px;
                    color: var(--v-warning-base); 
                  "
                  >
                    {{ m.MndUnplanned - m.MndUnplannedConfirmate }}
                  </h1>
                </v-col>
              </v-row>
              <v-progress-linear
                stream
                rounded=""
                :buffer-value="getPorcentaje(m.MndUnplanned, m.MndUnplannedConfirmate)"
                v-if="!horizontal"
                :value="getPorcentaje(m.MndUnplanned, m.MndUnplannedConfirmate)"
                color="warning"
              ></v-progress-linear>
            </v-card-text>
          </div>
        </v-card>
      </v-col>

      <v-row v-else>
        <v-col lg="6" elevation="2" rounded="" color="grey lighten-4" dense v-for="m in meal.menus" style="margin-bottom: 5px:padding:0px;">
          <div>
            <v-card-text style="background: white; padding-top: 0px;padding-bottom: 0px ;border-radius:5px !important;">
              <v-toolbar height="25" rounded="" dense elevation="0" class="">
                <v-toolbar-title style="  font-size: 16px !important; color: var(--v-primary-base); font-weight: 400 !important;  ">
                  <b>{{ m.MealTypeText }} {{ m.MenuTypeText }}</b></v-toolbar-title
                >
              </v-toolbar>
              <v-row>
                <v-col lg="6" >
                  <v-row>
                    <v-col lg="4" style="padding-right: 12px;">
                      <p class="text-center">Reservas</p>
                      <h1 class="text-center" style=" font-size: 32px; margin: 0px;padding: 0px; color: var(--v-success-base); ">
                        {{ m.MndReserve }}
                      </h1>
                    </v-col>
                    <v-col lg="4" style="padding-right: 12px;">
                      <p class="text-center">Atendidas</p>
                      <h1 class="text-center" style=" font-size: 32px; margin: 0px;padding: 0px;color: var(--v-success-base); ">
                        {{ m.MndReserveConfirmate }}
                      </h1>
                    </v-col>
                    <v-col lg="4" style="padding-right: 12px;">
                      <p class="text-center">Pendiente</p>
                      <v-spacer></v-spacer>
                      <h1 class="text-center" style=" font-size: 32px; margin: 0px;padding: 0px; color: var(--v-success-base); ">
                        {{ m.MndReserve - m.MndReserveConfirmate }}
                      </h1>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col lg="6" >
                  <v-row>
                    <v-col lg="4" class="" style="padding-right: 12px;">
                      <p class="text-center">Reservas</p>
                      <h1
                        class="text-center"
                        style="font-size: 32px;  margin: 0px; padding: 0px;
                    color: var(--v-warning-base); 
                  "
                      >
                        {{ m.MndUnplanned }}
                      </h1>
                    </v-col>
                    <v-col lg="4" class="" style="padding-right: 12px;">
                      <p class="text-center">Atendidas</p>
                      <h1
                        class="text-center"
                        style="
                    font-size: 32px;
                    margin: 0px;
                    padding: 0px;
                    color: var(--v-warning-base); 
                  "
                      >
                        {{ m.MndUnplannedConfirmate }}
                      </h1>
                    </v-col>
                    <v-col lg="4" style="padding-right: 12px;">
                      <p class="text-center">Pendiente</p>
                      <h1
                        class="text-center"
                        style=" 
                    font-size: 32px;
                    margin: 0px;
                    padding: 0px;
                    color: var(--v-warning-base); 
                  "
                      >
                        {{ m.MndUnplanned - m.MndUnplannedConfirmate }}
                      </h1>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </v-col>
      </v-row>
    </v-row>
  </div>
</template>
<script>
import Vue from "vue";
import signalr from "signalr";
import MenuTypeService from "../../services/DinningHall/MenuTypeService";
import MenuDailyService from "../../services/DinningHall/MenuDailyService";
import MealTypeService from "../../services/DinningHall/MealTypeService";

export default {
  name: "TimeRealReserve",
  components: {},
  props: {
    MltID: {
      type: Number,
      default: null,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    proxy: null,
    valueReserve: 10,
    messages: [],
    menus: [],
    meals: [],
    userName: "",
    userMessage: "",
  }),
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
    document.head.appendChild(recaptchaScript);

    recaptchaScript.addEventListener("load", () => {
      $.connection.hub.url = this.$const.URL + "/signalr";
      var chat = $.connection.notification;

      chat.client.sendMessage = (name, message) => {
        this.loadMeals(
          message.filter((x) => this.$moment(x.MndDate).format("YYYY-MM-DD") == this.$moment().format("YYYY-MM-DD") && x.MndStatus == 2)
        );
      };

      //  console.log($.connection.hub.start())

      //{ jsonp: true }
      $.connection.hub.start({ jsonp: true }).done((e) => {
        $("#d").click(function() {
          alert();
          chat.server.send("ds", "dsfg");
        });
      });
    });

    //
  },

  methods: {
    getPorcentaje(full, value) {
      if (full == 0) return 0;
      return Math.round((value / full) * 100);
    },
    loadMeals(menus) {
      this.meals = [];
      menus.forEach((element) => {
        let exist = null;
        exist = this.meals.find((x) => {
          return x.MltID == element.MltID;
        });
        element.MndDate = this.$moment(element.MndDate).format("YYYY-MM-DD");

        if (exist == null) {
          element.MndDate = this.$moment(element.MndDate).format("YYYY-MM-DD");

          let m = { MltID: element.MltID, menus: [element] };
          this.meals.push(m);
        } else {
          element.MndDate = this.$moment(element.MndDate).format("YYYY-MM-DD");
          exist.menus.push(element);
        }
      });
    },
  },
  created() {
    MenuDailyService.listHigherNow().then((r) => {
      if (r.data.length > 0)
        this.loadMeals(r.data.filter((x) => this.$moment(x.MndDate).format("YYYY-MM-DD") <= this.$moment().format("YYYY-MM-DD") && x.MndStatus == 2));
    });

    //});
  },
};
</script>
