import Vue from 'vue'

const menu = "dinningHall/menutype/";

export default {

    pagination(dtr, user, axios) { console.log(axios.token); return Vue.prototype.$http.post(menu + "pagination", dtr, { cancelToken: axios.token }); },
    getUrllist() { return Vue.prototype.$http.defaults.baseURL + "/" + menu + "list" },

    save(obj, usr) {
        return Vue.prototype.$http.post(menu + "save", obj, { params: { usr: usr } });
    }
}