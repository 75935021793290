<template>
  <div class="background" @click="documentFocus()">
    <v-col
      ><v-row>
        <v-col lg="2" md="2">
          <s-text
            label="Documento"
            autofocus
            dark
            ref="document"
            v-model="document"
            @keyupEnter="confirm()"
          ></s-text
        ></v-col>

        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="fullScreen()"
          v-if="this.$fun.IsFullScreen()"
          ><v-icon>mdi-arrow-collapse</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          @click="fullScreen()"
          v-if="!this.$fun.IsFullScreen()"
          ><v-icon> mdi-arrow-expand</v-icon></v-btn
        >
      </v-row>
      <v-row justify="center">
        <v-col lg="3" v-for="m in meals">
          <v-card class="mx-auto" style="height: 88%">
            <v-list-item>
              <v-list-item-avatar color="grey"
                ><img src="./../../../public/static/logo.jpg" alt=""
              /></v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  style="font-size: 32px !important"
                  class="headline"
                  ><b>{{ m.McsPersonName }}</b></v-list-item-title
                >
                <v-list-item-subtitle style="font-size: 24px"
                  >{{ m.McsPersonPaternalSurname }}
                  {{ m.McsPersonMaternalSurname }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-img :src="m.image" contain></v-img>
          </v-card>
          <v-card-actions
            v-if="m.McsID"
            :style="{ background: activeColor(m.ContentText) }"
          >
            <v-row justify="center">
              >
              <div class="text-center" style="color: white; font-size: 44px">
                <b> {{ m.MenuTypeText }} {{ m.MealTypeText }}</b>
              </div>
            </v-row>
          </v-card-actions>
          <v-card-actions v-else style="background: var(--v-error-base)">
            <v-row justify="center"
              ><div class="text-center" style="color: white; font-size: 44px">
                <b> SIN RESERVA</b>
              </div>
            </v-row>
          </v-card-actions>
        </v-col>
      </v-row>
      <time-real-reserve
        style="border-radius: 5px !important"
        :MltID="38"
        horizontal
        :columnMenu="12"
      ></time-real-reserve>
    </v-col>
  </div>
</template>

<script>
import TimeRealReserve from "./../../views/DinningHall/TimeRealReserve";
import HelperService from "./../../services/HelperService";
import MealConsumption from "./../../services/DinningHall/MealConsumptionService";
export default {
  name: "Consumption",
  components: { TimeRealReserve },
  data: () => ({
    full: false,
    img: "",
    document: "",
    mealConsumption: null,
    meals: [],
    lengthMeals: 4,
  }),
  computed: {},
  methods: {
    documentFocus() {
      this.$refs.document.focus();
    },
    fullScreen() {
      this.$fun.screen();
      this.$refs.document.focus();
    },
    activeColor(color) {
      return "var(--v-" + color + "-base";
    },
    confirm() {
      if (this.document.trim().length < 1) return;
      if (
        this.meals.find((x) => x.McsPersonDocumentNumber == this.document) ==
        null
      ) {
        MealConsumption.confirm(this.document).then(
          (r) => {
            if (r.data != null) {
              console.log('traemos los datos del confirm', r.data);
              let data = this.document.split(",");
              HelperService.getPhotoWorker(
                data[0],
                //70032754,6,1,25903,PANTA GARCIA,IRVIN POOL
                this.$fun.getUserID()
              ).then((rsp) => {
                if (r.data == null) {
                  console.log('traemos la foto', rsp.data )
                  r.data = {};
                }
                const blob = new Blob([rsp.data], {
                  type: "image/jpg",
                });
                let meal = r.data;
                let image = blob;
                var reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onloadend = () => {
                  meal.image = reader.result;

                  if (this.meals.length >= this.lengthMeals) this.meals.pop();

                  this.meals.unshift(meal);
                  this.document = "";
                };
              });
            } else {
              this.$fun.alert("No hay registro del documento", "warning");
              this.document = "";
            }
          },
          (e) => {
            this.document = "";
          }
        );
      } else this.document = "";
    },
  },
  created() {
    // this.$fun.screen();
  },
};
</script>
