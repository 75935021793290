import Vue from 'vue'

const menu="dinningHall/menudaily/";

export default {

    pagination(dtr,token){return Vue.prototype.$http.post(menu+"pagination",dtr);},
    save(obj,usr){
        return Vue.prototype.$http.post(menu+"save",obj,{    params:{usr:usr}});
    },
    ListNoDaily(dtr){return Vue.prototype.$http.post(menu+"ListNoDailyByDate",dtr);},
    listHigherNow(){
        return Vue.prototype.$http.post(menu+"ListHigherNow",{});
    },
    approval(obj){
        return Vue.prototype.$http.post(menu+"approval",obj);
    },
    buscaPorID(MndID){
        return Vue.prototype.$http.post(menu+"programacionporid",{},{params:{MndID:MndID}});
    },
    realTime(){
        return Vue.prototype.$http.post(menu+"realtime");
    },
    report(begin,end){
        return Vue.prototype.$http.post(menu+"report",{},{params:{begin:begin,end:end},
        responseType: "blob",
      });
    }
} 