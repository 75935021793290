import Vue from 'vue'

const menu="dinningHall/mealconsumption/";

export default {
    ReservesPerson(document){return Vue.prototype.$http.post(menu+"reservesPerson",{},{    params:{dtr:document}});},
    pagination(dtr,token){return Vue.prototype.$http.post(menu+"pagination",dtr);},
    save(obj,usr){
        return Vue.prototype.$http.post(menu+"save",obj,{    params:{usrID:usr}});
    },
    reserveMinus(obj,usr){
        return Vue.prototype.$http.post(menu+"reservesMinus",obj,{    params:{usrID:usr}});
    },
    confirm(document){
        return Vue.prototype.$http.post(menu+"confirm",{},{    params:{document:document}});
    }
} 